import React from "react";
import "../styles/FooterSection.css";
import { Link } from 'react-router-dom';

const FooterSection = () => {
  return (
    <div className="footer-section">
      <img src="logo.png" alt="Logo" className="footer-logo" />
      <nav className="footer-links">
        <a href="#home">Home</a>
        <a href="#features">Features</a>
        <a href="#pricing">Pricing</a>
        <a href="#contact">Contact</a>
      </nav>
      <div className="footer-copyright">
        <p>© 2025 ClipyCam All Rights Reserved</p>
        <nav>
          <a href="/user-agreement">User Licensing Agreement</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="#faq">FAQs</a>
        </nav>
      </div>
    </div>
  );
};

export default FooterSection;
