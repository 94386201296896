import React from "react";
import "../styles/PricingSection.css";

const PricingSection = () => {
  return (
    <div className="pricing-section" id="pricing">
      <h2 className="pricing-heading">Simple, transparent pricing</h2>
      <p className="pricing-subheading">No contracts. No surprise fees.</p>

      <div className="pricing-cards">
        {/* Free Plan */}
        <div className="pricing-card">
          <h3 className="plan-title">Free</h3>
          <p className="plan-description">Free Basic App Access for exploring our Features</p>
          <button className="choose-plan-button free">Get Started</button>
          <ul className="pricing-features">
            <li>Pause & Resume</li>
            <li>Albums</li>
            <li>ClipyCam - Logo</li>
          </ul>
        </div>

        {/* Yearly Plan */}
        <div className="pricing-card pro">
          <div className="billed-tag yearly">
            <span className="tag-label">Billed Yearly</span>
            <span className="tag-sub">Save up to 20%</span>
          </div>
          <h3 className="plan-title">Pro</h3>
          <p className="plan-description">Take Your Photo/Video Journey to the Next Level with Pro Plan.</p>
          <p className="pricing-amount yearly">$11.99 <span>/month</span></p>
          <button className="choose-plan-button">Select Pro</button>
          <ul className="pricing-features">
            <li>Remove ClipyCam’s Logo</li>
            <li>Clip</li>
            <li>Continue Recording</li>
            <li>Annotation</li>
          </ul>
        </div>

        {/* Monthly Plan */}
        <div className="pricing-card pro popular">
          <div className="billed-tag monthly">
            <span className="tag-label">Billed Monthly</span>
          </div>
          <h3 className="plan-title">Pro</h3>
          <p className="plan-description">Take Your Photo/Video Journey to the Next Level with Pro Plan.</p>
          <p className="pricing-amount monthly">$14.99 <span>/month</span></p>
          <button className="choose-plan-button">Select Pro</button>
          <ul className="pricing-features">
            <li>Remove ClipyCam’s Logo</li>
            <li>Clip</li>
            <li>Continue Recording</li>
            <li>Annotation</li>
          </ul>
        </div>
      </div>

          {/* Free Trial Section */}
          <div className="free-trial-section">
            <h3 className="trial-heading">
            Download now and <span className="highlight">Get 7 days Free trial</span>
            </h3>
            <p className="trial-subtext">
              We are doing good tell others, if not please tell us
            </p>
            <button className="app-store-button">
              <img src="Vector.png" alt="App Store" />
              <div className="app-store-text">
                <span className="app-available">We are Available on</span>
                <span className="app-title">App Store</span>
            </div>
          </button>
        </div>

    </div>
    
  );
};

export default PricingSection;
