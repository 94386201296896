import React, { useState, useRef, useEffect } from "react";
import "../styles/TabSlider.css";

const TabbedSlider = ({ tabContent }) => {
  const [activeTab, setActiveTab] = useState(tabContent[0]?.key || "");
  const tabContentRef = useRef(null);
  const tabRefs = useRef({});
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const velocity = useRef(0);
  const momentumID = useRef(null);

  useEffect(() => {
    const container = tabContentRef.current;
    if (!container) return;

    const handleScroll = () => {
      let closestTab = activeTab;
      let minDistance = Infinity;

      tabContent.forEach((tab) => {
        const element = tabRefs.current[tab.key];
        if (element) {
          const distance = Math.abs(element.offsetLeft - container.scrollLeft);
          if (distance < minDistance) {
            minDistance = distance;
            closestTab = tab.key;
          }
        }
      });

      setActiveTab(closestTab);
    };

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [activeTab, tabContent]);

  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
    const tabElement = tabRefs.current[tabKey];

    if (tabElement && tabContentRef.current) {
      tabContentRef.current.scrollTo({
        left: tabElement.offsetLeft,
        behavior: "smooth",
      });
    }
  };

  const handleMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - tabContentRef.current.offsetLeft;
    scrollLeft.current = tabContentRef.current.scrollLeft;
    velocity.current = 0;
    cancelAnimationFrame(momentumID.current);
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const x = e.pageX - tabContentRef.current.offsetLeft;
    const move = (x - startX.current) * 0.4;
    tabContentRef.current.scrollLeft = scrollLeft.current - move;
    velocity.current = move * 0.1;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    applyMomentum();
  };

  const applyMomentum = () => {
    if (Math.abs(velocity.current) > 0.5) {
      tabContentRef.current.scrollLeft -= velocity.current;
      velocity.current *= 0.95;
      momentumID.current = requestAnimationFrame(applyMomentum);
    } else {
      cancelAnimationFrame(momentumID.current);
    }
  };

  return (
    <div className="tabbed-slider-container">
      <div className="tab-menu">
        {tabContent.map((tab) => (
          <button
            key={tab.key}
            className={`tab-button ${activeTab === tab.key ? "active" : ""}`}
            onClick={() => handleTabClick(tab.key)}
          >
            {tab.key}
          </button>
        ))}
      </div>

      <div
        className="tab-content"
        ref={tabContentRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {tabContent.map((tab, index) => (
          <div
            key={index}
            ref={(el) => (tabRefs.current[tab.key] = el)}
            className={`tab-item ${tab.layout === "left" ? "image-left" : "image-right"}`}
          >
            {tab.layout === "left" ? (
              <>
                <div className="tab-image-container">
                  <img src={tab.image} alt={tab.key} className="tab-image" />
                </div>
                <div className="content-text">
                  <h2 className="tab-title">{tab.title}</h2>
                  <p className="tab-description">{tab.description}</p>
                  <button className="how-it-works-button">{tab.button}</button>
                </div>
              </>
            ) : (
              <>
                <div className="content-text">
                  <h2 className="tab-title">{tab.title}</h2>
                  <p className="tab-description">{tab.description}</p>
                  <button className="how-it-works-button">{tab.button}</button>
                </div>
                <div className="tab-image-container">
                  <img src={tab.image} alt={tab.key} className="tab-image" />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabbedSlider;
