import React, { useState,useEffect } from "react";
import "../styles/ContactSection.css";


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const [responseMessage, setResponseMessage] = useState("");

  // Load Turnstile script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get Turnstile Token
    const token = document.querySelector('input[name="cf-turnstile-response"]')?.value;

    if (!token) {
      setResponseMessage("Please complete the CAPTCHA.");
      return;
    }

    try {
      const res = await fetch(`${apiBaseUrl}/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...formData,
          captchaToken: token
        })
      });
      if (!res.ok) {
        const errorText = await res.text(); // This will be HTML if 404
        throw new Error(`API error: ${res.status} - ${errorText}`);
      }
      const result = await res.json();
      if (res.ok){
        // clear form if success
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: ""
        });

        // reset the Turnstile captcha 
        if (window.turnstile) {
          window.turnstile.reset();
        }
      }
      setResponseMessage(result.message || "Message sent!");
    } catch (err) {
      console.error("Email sending error:", err);
      setResponseMessage("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="contact-section" id="contacts">
      <h2 className="contact-heading">Contact</h2>
      <p className="contact-subheading">
        If you feel we are doing good, tell others. If not, please tell us.
      </p>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="name"
            placeholder="Name"
            className="form-control name-input"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="form-control email-input"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          className="form-control subject-input"
          value={formData.subject}
          onChange={handleChange}
          required
        />
        <textarea
          name="message"
          placeholder="Message"
          className="form-control message-input"
          rows="5"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        {/* CloudFlare Turnstile Widget */}
        <div
          className="cf-turnstile"
          data-sitekey="0x4AAAAAABCV3f1SAuxonzeA"
        ></div>
        <button type="submit" className="submit-button">
          Submit
        </button>
        {responseMessage && <p style={{ marginTop: "10px" }}>{responseMessage}</p>}
      </form>
    </div>
  );
};

export default ContactSection;