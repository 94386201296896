import React from "react";
import TabbedSlider from "./TabSlider";
import "../styles/UserSelection.css";

const UserSelection = ({
  selectedUser,
  setSelectedUser,
  selectedSubCategory,
  setSelectedSubCategory,
}) => {
  const categories = {
    "Individual / Creator": [
      { name: "Influencer", image: "Influencer.png" },
      { name: "DIY", image: "DIY.png" },
      { name: "Photographer", image: "Photographer.png" },
      { name: "Videographer", image: "Videographer.png" },
      { name: "Editor", image: "Editor.png" }
    ],
    "Business User": [
      { name: "Construction Company", image: "Construction Company.png" },
      { name: "Roofing Company", image: "Roofing.png" },
      { name: "Construction Agency", image: "Construction Agency.png" },
      { name: "Contract Company", image: "Contract Company.png" }
    ]
  };

  const individualTabContent = [
        { key: "Pause & Resume", title: "Record only important moment with Pause & Resume", description: "Pause video anytime and resume whenever you want to.", image: "PauseResume.png", button: "How it works?", layout: "left" },
        { key: "Switch", title: "Switch your Camera while Video Recording with Live Switch", description: "Record both side of the world with Live Switch.", image: "Switch.png", button: "How it works?", layout: "right" },
        { key: "Branding", title: "Custom Branding to Build Your Identity", description: "You can add your logo, client logo, time stamp many more customized addon to your photos and videos.", image: "Branding.png", button: "How it works?", layout: "left" },
        { key: "Timer", title: "Capture perfect click and Video with Photo and Video timer", description: "Set a timer and take your position to record your videos and images without any helping hand", image: "Timer.png", button: "How it works?", layout: "right" },
        { key: "Flash", title: "Video Recording Made Easy in Low Light situation with Live Flash", description: "Live Flash will sensor the low light and turn on the flash for you to capture your video in low light situation.", image: "LiveFlash.png", button: "How it works?", layout: "left" },
        { key: "Continue Video", title: "No need to merge multiple videos from now. You can record it with Continue Video", description: "Tired of recording multiple video and merge it in third party applications. We have a solution of Continue video where you can record on top of any video and it will become a full video once you complete the recording.", image: "PauseResume.png", button: "How it works?", layout: "right" },
      ];

  const businessTabContent = [
    { key: "Draw & Annonate", title: "In Live video mark and add texts with Draw & Annotate", description: "Tired of recording multiple video and merge it in third party applications. We have a solution of Continue video where you can record on top of any video and it will become a full video once you complete the recording.", image: "D&A.png", button: "How it works?", layout: "left" },
    { key: "Continue Recording", title: "Record Before After property status with Continue Recording", description: "Pause video anytime and resume whenever you want to", image: "ConRecording.png", button: "How it works?", layout: "right" },
    { key: "Clip", title: "Cut Important moments from full video recording with Clip feature", description: "Record Both side of world with Live Switch", image: "ClipFeature.png", button: "How it works?", layout: "right" },
    { key: "Branding", title: "Custom Branding tot build your Branding", description: "You can add your logo, client logo, time stamp many more customized addon to your photos and videos", image: "Branding.png", button: "How it works?", layout: "right" },
    { key: "Live Flash", title: "Video recording made easy in low light situation with Live Flash", description: "Live Flash will sensor the low light and turn on the  flash for you to capture your video in low light situation.", image: "LiveFlash.png", button: "How it works?", layout: "right" },
  ];

  const handleUserSelection = (userType) => {
    setSelectedUser(userType);
    setSelectedSubCategory(null);
  };

  const handleSubCategorySelection = (subcategory) => {
    setSelectedSubCategory(subcategory);
  };

  if (selectedUser && selectedSubCategory) {
    const tabContent = selectedUser === "Individual / Creator" ? individualTabContent : businessTabContent;

    return (
      <div className="user-selection-container">
        <div className="selected-options">
          <div className="selected-user">
            <img
              src={selectedUser === "Individual / Creator" ? "Individual.png" : "Business.png"}
              alt={selectedUser}
              className="selected-avatar"
            />
            <p className="selected-label">{selectedUser}</p>
            <span className="user-checkmark">✔</span>
          </div>

          <div className="selected-subcategory">
            <img
              src={categories[selectedUser]?.find((item)=> item.name===selectedSubCategory)?.image}
              alt={selectedSubCategory}
              className="selected-avatar"
            />
            <p className="selected-label">{selectedSubCategory}</p>
            <span className="user-checkmark">✔</span>
          </div>
        </div>

        <h3 className="great-choice">Great Choice!</h3>
        <p className="choice-description">
          ClipyCam picked the best benefits that can help you to grow
        </p>

        <TabbedSlider tabContent={tabContent} />
      </div>
    );
  }

  return (
    <div className="user-selection-container">
      <div className="user-options-container">
        <div className="user-options">
          {Object.keys(categories).map((userType) => (
            <div
              key={userType}
              className={`user-option ${
                selectedUser === userType ? "active" : ""
              }`}
              onClick={() => handleUserSelection(userType)}
            >
              <img
                src={userType === "Individual / Creator" ? "Individual.png" : "Business.png"}
                alt={userType}
                className="user-avatar"
              />
              <p className="user-label">{userType}</p>
              {selectedUser === userType && <span className="user-checkmark">✔</span>}
            </div>
          ))}
        </div>
      </div>
      {selectedUser && (
        <>
          <h3 className="user-question">What are you doing ?</h3>
          <div className="category-options">
            {categories[selectedUser].map(({ name, image }) => (
              <div
                key={name}
                className={`category-option ${
                  selectedSubCategory === name ? "active" : ""
                }`}
                onClick={() => handleSubCategorySelection(name)}
              >
                <img src={image} alt={name} className="category-avatar" />
                <p className="category-label">{name}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default UserSelection;
