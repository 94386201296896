import React, { useState } from 'react';
import '../styles/Section1.css'; // Updated CSS

const Section1 = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    const video = document.getElementById('video');
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className="section section1">
      <div className="full-width-component">
        {/* Left Side: Text with Watermark */}
        <div className="left-part">
          {/* <div className="watermark" style={{ backgroundImage: "url('/watermark_gray.png')" }}></div>  */}
          <p>Enhance Productivity without the hassle of Editing and Organizing.</p>
        </div>

        {/* Right Side: Video with Play Button */}
        <div className="right-part">
          <div className="video-container">
            {!isPlaying && (
              <button className="play-button" onClick={handlePlay}>
                ▶
              </button>
            )}
            <video
              id="video"
              src="/path/to/your/video.mp4"
              poster="/home.jpg"
              className="video"
              controls={isPlaying} // Show controls only when playing
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
