import React, { useEffect } from "react";
import "../styles/UserAgreement.css";

const UserAgreement = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termageddon.com/js/termageddon.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="user-agreement-container">
      <h1>User Licensing Agreement</h1>
      <div
        id="policy"
        data-policy-key="UVZOQ01ub3daVzlZTVcxcVQzYzlQUT09"
        data-extra="h-align=left&table-style=accordion"
      >
        Please wait while the agreement is loaded. If it does not load, please{" "}
        <a
          rel="nofollow"
          href="https://app.termageddon.com/api/policy/UVZOQ01ub3daVzlZTVcxcVQzYzlQUT09?h-align=left&table-style=accordion"
          target="_blank"
          aria-label="View Agreement"
        >
          click here to view the agreement
        </a>.
      </div>
    </div>
  );
};

export default UserAgreement;
