import React, { useEffect } from 'react';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termageddon.com/js/termageddon.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="privacy-wrapper">
      <div className="privacy-container">
        <div
          id="policy"
          data-policy-key="VlhGS2IxQnRRVFl3VURKSU1HYzlQUT09"
          data-extra="h-align=left&table-style=accordion"
        >
          Please wait while the policy is loaded. If it does not load, please{' '}
          <a
            rel="nofollow"
            href="https://app.termageddon.com/api/policy/VlhGS2IxQnRRVFl3VURKSU1HYzlQUT09?h-align=left&table-style=accordion"
            target="_blank"
            aria-label="View Privacy Policy"
          >
            click here to view the policy
          </a>.
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
