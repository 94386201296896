import React, { useState } from 'react';
import './App.css';
import Navbar from './views/Navbar';
import Sections from './views/Sections';
import PrivacyPolicy from './views/PrivacyPolicy';
import UserAgreement from './views/UserAgreement';
import { Routes, Route, Navigate } from 'react-router-dom';

function App() {
  const [userSelected, setUserSelected] = useState(false);

  return (
    <>
      <Navbar userSelected={userSelected} />
      <Routes>
        <Route
          path="/"
          element={<Sections setUserSelected={setUserSelected} />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/privacy/*" element={<Navigate to="/privacy-policy" replace />} />
        <Route path="/user-agreement" element={<UserAgreement/>} />

      </Routes>
    </>
  );
}

export default App;
