import React from 'react';
import Section1 from './Section1';
import BenefitsSection from "./BenefitsSection";
import '../styles/Sections.css';

const Sections = ({ setUserSelected }) => {
  return (
    <>
      <Section1 />
      <BenefitsSection setUserSelected={setUserSelected} /> 
    </>
  );
};

export default Sections;
