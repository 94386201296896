import React, { useState,useEffect } from "react";
import UserSelection from "./UserSelection";
import FooterSection from "./FooterSection";
import PricingSection from "./PricingSection";
import ContactSection from "./ContactSection";
import "../styles/BenefitsSection.css";

const BenefitsSection = ({setUserSelected}) => {
  const [selectedUser, setSelectedUser] = useState(null); // Main category state
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // Subcategory state

  useEffect(() => {
    if (selectedUser && selectedSubCategory) {
      setUserSelected(true);
    }
  }, [selectedUser, selectedSubCategory, setUserSelected]);

  return (
    <div className="benefits-section container" id = "benefits">
      <h2 className="benefits-heading">Benefits</h2>
      <p className="benefits-subheading">
        Change your way of your recording experience
      </p>
      <h3 className="user-question">Choose from below Who are you?</h3>

      {/* User Selection Component */}
      <UserSelection
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        selectedSubCategory={selectedSubCategory}
        setSelectedSubCategory={setSelectedSubCategory}
      />

      {/* Conditionally render Pricing and Contact Sections */}
      {selectedUser && selectedSubCategory && (
        <>
          <PricingSection />
          <ContactSection />
        </>
      )}

      {/* Footer Section */}
      <FooterSection />
    </div>
  );
};

export default BenefitsSection;