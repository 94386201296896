import React from 'react';
import '../styles/Navbar.css';

const Navbar = ({ userSelected }) => {
  const handleScroll = (e, sectionId) => {
    if (!userSelected) {
      e.preventDefault();
      alert("Please select a user type first.");
    } else {
      e.preventDefault();
      setTimeout(() => {
        const el = document.getElementById(sectionId);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div className="container-fluid">
        {/* ✅ Left Section: Hamburger + Logo */}
        <div className="navbar-left">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="/">
            <img src="logo.png" alt="ClipyCam Logo" className="navbar-logo" />
          </a>
        </div>

        {/* Center Nav Links */}
        <div className="d-flex ms-3 nav-links-group">
          <a className="nav-link" href="#benefits" onClick={(e) => handleScroll(e, "benefits")}>
            Benefits
          </a>
          <a className="nav-link" href="#pricing" onClick={(e) => handleScroll(e, "pricing")}>
            Pricing
          </a>
          <a className="nav-link" href="#contacts" onClick={(e) => handleScroll(e, "contacts")}>
            Contact
          </a>
        </div>

        {/* Right Side Buttons */}
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <div className="d-flex">
            <button className="btn btn-outline-success me-2" type="button">Sign up</button>
            <button className="btn btn-success" type="button">Sign in</button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
